import React, { Component } from 'react';
import Layout from '../components/layout'
import { Helmet } from 'react-helmet';
import background from '../images/nyse.jpg';
import NewsletterGallery from '../components/newsletter-gallery'

class Newsletters extends Component {
  

  render(){
  return (
    <Layout>
        <Helmet>
          <title>TOTM Newsletters</title>
      </Helmet>
        <section id="page-title" className="page-title-parallax page-title-dark" style={{backgroundImage: `url(${background})`, padding: '120px 0'}} data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
            <div className="container clearfix page-title">
                <h1>TOTM Newsletters</h1>
                <span>See our past and current TOTM Newsletters</span>
            </div>
        </section>
        <section id="content">
            <div className="content-wrap">
              <NewsletterGallery />
            </div>    
        </section>          
    </Layout>
  )
  }
}

export default Newsletters