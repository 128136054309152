import React, { Component } from 'react';
import newsletter_holder from '../data/newsletters.json';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";

class NewsletterGallery extends Component {
    constructor(props){
        super(props);
        this.newsletters = newsletter_holder.newsletters ? newsletter_holder.newsletters : [""];
        
        if(this.newsletters.length > 1){
            this.newsletters.sort((a,b) => {
                return a.date - b.date;
            })
        }

        this.state =  {
          index: 0,
          newsletter: this.newsletters[0],
          showPrevious: false,
          showNext: this.newsletters.length > 1
        }
        
        this.changeNewsletter = this.changeNewsletter.bind(this);
        this.incrementNewsletter = this.incrementNewsletter.bind(this);
        this.decrementNewsletter = this.decrementNewsletter.bind(this);
      }
    
      incrementNewsletter(e){
        e.preventDefault();
        this.changeNewsletter(1);
      }
    
      decrementNewsletter(e){
        e.preventDefault();
        this.changeNewsletter(-1)
      }
    
    changeNewsletter(increment){
        let next = this.state.index + increment;
        if(next < 0)
            next = 0;
        if(next >= this.newsletters.length)
            next = this.newsletters.length - 1;
        
        this.setState({
            index: next,
            newsletter: this.newsletters[next],
            showPrevious: next > 0,
            showNext: next < this.newsletters.length-1
        });
    }
    render(){
        return(
            <div className="container clearfix">
                <div className="row col-mb-50 button-padding">
                    <div className="col-4 justify-content-start">
                        <button name="prev-newsletter" className="btn btn-secondary" style={{display: this.state.showPrevious ? "block" : "none"}} onClick={this.decrementNewsletter}><FaArrowCircleLeft /> Previous</button>
                    </div>
                    <div className="col-4 offset-4 justify-content-end">
                        <button name="next-newsletter" className="btn btn-secondary" style={{float: "right", display: this.state.showNext ? "block" : "none"}} onClick={this.incrementNewsletter}>Next <FaArrowCircleRight /></button>
                    </div>
                </div>
                <div className="row col-mb-50">
                    <div className="col-12">
                        <div className="markdown-content">
                        <ReactMarkdown plugins={[gfm]} children={this.state.newsletter.content} />
                        </div>
                    </div>
                </div>
                <div className="row col-mb-50 button-padding">
                    <div className="col-4">
                        <button name="prev-newsletter" className="btn btn-secondary" style={{display: this.state.showPrevious ? "block" : "none"}} onClick={this.decrementNewsletter}><FaArrowCircleLeft /> Previous</button>
                    </div>
                    <div className="col-4">
                    <p className="newsletter-page"> Newsletter {this.state.index + 1} of {this.newsletters.length}</p>
                    </div>
                    <div className="col-4">
                        <button name="next-newsletter" className="btn btn-secondary" style={{float: "right", display: this.state.showNext ? "block" : "none"}} onClick={this.incrementNewsletter}>Next <FaArrowCircleRight /></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsletterGallery